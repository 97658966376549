// Georgiou

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "Georgiou Group",
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          MLC MasterKey Business Super with Georgiou Group
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            By joining the company <b>Superannuation fund</b>, you benefit from group discount rates on fees and insurances. This means you pay much less than you would pay in a personal or individual plan.
          </p>
          <p>
            Members will be able to access <strong>Vivo Virtual Care</strong>, which provides advice and guidance from leading health experts, anywhere, anytime.
          </p>
          <p>
            This website provides all the information you need around the <b>Georgiou Group Superannuation Fund</b>. There are helpful super tools and calculators, and information on other services we offer if you require additional support with your financial well-being.
          </p>
          <div class="d-flex justify-content-around">
            <div class="col-lg-5 py-3 px-0 text-center text-lg-center">
              <a
                target="_blank"
                href="https://welcome.mlc.com.au/portal?id=Georgiou-e07556d5-b76b-42a8-b524-94a96eb16c12"
                class="btn-learn-more"
              >
                MLC Super fund
              </a>
            </div>
            <div class="col-lg-5 py-3 px-0 text-center text-lg-center">
              <a
                target="_blank"
                href="https://vivovirtualcare.com.au/"
                class="btn-learn-more"
              >
              Learn more about the Vivo Virtual Care
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p><b>Fees (per annum)</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Member Fee:
              <b>$78</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Administration Fee:
              <b>0.22%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Investment
              Fee:
              <b>0.84%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Trustee Levy:
              <b>0.02%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Transaction costs:
              <b>0.07%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> TOTAL:
              <b>$78 + 1.15%</b>
            </li>
          </ul>
          <p class='mt-2'>
            <b>Insurances</b>
          </p>
          <p><b>Death & Total Permanent Disability Cover:</b></p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              Permanent Staff : Death & Total & Permanent Disability = 15% x salary x years to age 65
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              Casual Staff : MLC Lifestage insurance
            </li>
          </ul>
          <p>
            <b>Income Protection (available to eligible members only): </b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              75% of salary with a benefit period of 2 years and a waiting period of 90 days.
            </li>
          </ul>
          <p>
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              MLC MySuper
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="https://www.mlc.com.au/" target="_blank">MLC MasterKey Business Super</a>
            </li>
          </ul>
        </div>
      </div>
      
      `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the Georgiou fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "Georgiou-New_Employee_Pack-October_2023.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "info",
        externalUrl:
          "https://www.mlc.com.au/adviser/forms-and-documents/pds/superannuation",
      },
      {
        title: "MLC Forms",
        description: "Find the form you need on the MLC website.",
        type: "form",
        externalUrl:
          "https://www.mlc.com.au/personal/forms-and-documents/forms/superannuation",
      },
    ],
    optIn: "MKBS_Choose_ Insurance_form_PMIF(M153909-0421).pdf",
    teamMembers: [
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "sharron.jpg",
        name: "Sharron Wharton-Street",
        phoneHref: "0862508493",
        phone: "08 6250 8493",
        email: "Sharron_WhartonStreet@ajg.com.au",
        social: "https://www.linkedin.com/in/sharron-wharton-street-801026161/",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "Georgiou Group",
  },
  superscore: true,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
